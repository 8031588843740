import React, {useState, useEffect} from 'react'
import {selectUser } from '../auth/users/userSlice'
import { useSelector } from 'react-redux'
import firebase from "firebase";
import { db } from '../auth/firebase'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';
import { Divider } from '@material-ui/core';
import front1 from '../assets/image/front.png'
import rear1 from '../assets/image/rear.png'
import right1 from '../assets/image/right.png'
import left1 from '../assets/image/left.png'
// import {businessname, bstreet, bcity, bemail, bphone} from '../models/European'
// import logo from '../assets/image/logo.png'


const SearchInvoice = () => {

  const user = useSelector(selectUser)
  const [url, setUrl] = useState(null);
  const [todos, setTodos] = useState([]);

  const getProfileImage = () => {
    // firebase.storage().ref(`users/${user.uid}/cropped-GottaWebDesign-02-6-scaled-345x219-1-160x93.jpg`).getDownloadURL().then(imgUrl => {setUrl(imgUrl)})
    firebase
    .storage()
    .ref('users')
    .child(user.uid + "/logo495x388.png")
    .getDownloadURL()
    .then((imgUrl) => {
      setUrl(imgUrl);
    });
  }

  useEffect(() => {
    setTimeout(() => {
      getProfileImage();
    }, 1000);
  }, [])



 // Get Todos
 const  getTodos = () => {
   db.collection(user.uid).onSnapshot(function(querySnapshot) {
     setTodos(
       querySnapshot.docs.map((doc) => ({
         id: doc.id,
         bcity: doc.data().bcity,
         bemail: doc.data().bemail,
         bstreet: doc.data().bstreet,
         businessname: doc.data().businessname,
         bphone: doc.data().bphone,
         bwebsite: doc.data().bwebsite,
         bidinvoice: doc.data().bidinvoice
       }))
     );
   });
 }

 useEffect(() => {
  getTodos();    
}, []) // blank to run only on first time
    const [inputfiltro, setInputFiltro] = useState('')
    const [filterPhone, setFilterPhone] = useState(null)

    const searchByPhone = useSelector(state => state.invoices.invoices)

    const  onChangeHandlerInput = (event, newValue) => {
        setInputFiltro(newValue ? newValue.Name : event.target.value);
    }
    const  onChangeHandlerInputID = (event, newValue) => {
      setInputFiltro(newValue ? newValue.Idinvoice : event.target.value);
  }

  const  onChangeHandlerInputPhone = (event, newValue) => {
    setInputFiltro(newValue ? newValue.Phone : event.target.value);
}

    const handlerSubmit = () => {
        setFilterPhone(searchByPhone.filter(item => item.Phone === inputfiltro))        
    }
    const handlerSubmitbyname = () => {
      setFilterPhone(searchByPhone.filter(item => item.Name === inputfiltro))        
  }

const handlerSubmitbyidinvoice = () => {
  setFilterPhone(searchByPhone.filter(item => 
      item.Idinvoice != null && String(item.Idinvoice) === String(inputfiltro)
  ));
};



   const print = () => {
      window.print();
    }
    return (
        <>
        <Container>
            <Row md={4} className='mt-2'>
                    <Link to='/account' style={{textDecoration: 'none', marginTop:10}}>
                      <ArrowBackIcon color='primary' />
                    </Link>                    
                    
                    <Autocomplete
                       size='small'
                       id="idPhone"
                       options={searchByPhone}
                       getOptionLabel={(option) => option.Phone || ''} // Aseguramos que no sea null
                       style={{ width: 180 }}
                       onInputChange={onChangeHandlerInputPhone} // Esto captura cuando se escribe texto
                       onChange={onChangeHandlerInputPhone} // Esto captura cuando se selecciona una opción
                       renderInput={(params) => (
                         <TextField
                           {...params}
                           id="Phone"
                           name="Phone"
                           label="--Phone--"
                           variant="standard"
                         />
                        )}
                        /> 
                  <Button  
                    onClick={handlerSubmit}                              
                    type="button"
                     variant="outlined"
                     color="primary"
                     size="small"
                     startIcon={<SearchIcon />}                  
                    >
                     Search
                    </Button> 
            </Row>
            <Row md={4} className='mt-2'>
                    <Link to='/account' style={{textDecoration: 'none', marginTop:10}}>
                      <ArrowBackIcon color='primary' />
                    </Link>                    
                    <Autocomplete
                       size='small'
                       id="idPhone"
                       options={searchByPhone}
                       getOptionLabel={(option) => option.Name || ''} // Aseguramos que no sea null
                       style={{ width: 180 }}
                       onInputChange={onChangeHandlerInput} // Esto captura cuando se escribe texto
                       onChange={onChangeHandlerInput} // Esto captura cuando se selecciona una opción
                       renderInput={(params) => (
                         <TextField
                           {...params}
                           id="Name"
                           name="Name"
                           label="--Name--"
                           variant="standard"
                         />
                        )}
                        /> 
                   {/* <Autocomplete                      
                      size='small'
                      id="idPhone"
                      options={searchByPhone}
                      getOptionLabel={(option) => option.Name || ''}
                      style={{ width: 180 }}
                      renderInput={(params) => <TextField {...params} id="Name" name="Name" type='text' label="--Name-- "  variant="standard"  onSelect={onChangeHandlerInput } />}
                    />  */}
                  <Button  
                    onClick={handlerSubmitbyname}                              
                    type="button"
                     variant="outlined"
                     color="primary"
                     size="small"
                     startIcon={<SearchIcon />}                  
                    >
                     Search
                    </Button> 
            </Row>
            <Row md={4} className='mt-2'>
                    <Link to='/account' style={{textDecoration: 'none', marginTop:10}}>
                      <ArrowBackIcon color='primary' />
                    </Link>                    
                    
                    <Autocomplete
                       size='small'
                       id="idPhone"
                       options={searchByPhone}
                       getOptionLabel={(option) => option?.Idinvoice ? option.Idinvoice.toString() : ''} // Aseguramos que no sea null
                       style={{ width: 180 }}
                       onInputChange={onChangeHandlerInputID} // Esto captura cuando se escribe texto
                       onChange={onChangeHandlerInputID} // Esto captura cuando se selecciona una opción
                       renderInput={(params) => (
                         <TextField
                           {...params}
                           id="Idinvoice"
                           name="Idinvoice"
                           label="--Idinvoice--"
                           variant="standard"
                         />
                        )}
                        /> 
                   {/* <Autocomplete                      
                      size='small'
                      id="idPhone"
                      options={ searchByPhone}
                      getOptionLabel={(option) => String(option.Idinvoice)}
                      style={{ width: 180 }}
                      renderInput={(params) => <TextField {...params} id="Id" name="Id" type='text' label="--Id-- "  variant="standard"  onSelect={onChangeHandlerInput } />}
                    />  */}
                  <Button  
                    onClick={handlerSubmitbyidinvoice}                              
                    type="button"
                     variant="outlined"
                     color="primary"
                     size="small"
                     startIcon={<SearchIcon />}                  
                    >
                     Search
                    </Button> 
            </Row>
            
            {/* <Row>
                <Col className='mt-2'>
                    <Row style={{margin: 5}}>
                    <Link to='/account' style={{textDecoration: 'none', marginTop:10}}>
                      <ArrowBackIcon color='primary' />
                    </Link>                    
                    <Divider variant="middle" />
                    <Autocomplete                      
                      size='small'
                      id="idPhone"
                      options={ searchByPhone}
                      getOptionLabel={(option) => option.Phone}
                      style={{ width: 180 }}
                      renderInput={(params) => <TextField {...params} id="Phone" name="Phone" type='text' label="--Phone Number-- "  variant="standard"  onSelect={onChangeHandlerInput } />}
                    /> 
                    <Divider variant="middle" />
                    <Button  
                        onClick={handlerSubmit}                              
                        type="button"
                         variant="outlined"
                         color="primary"
                         size="small"
                         startIcon={<SearchIcon />}                  
                        >
                         Search
                    </Button>                    
                    </Row>
                </Col>               
            </Row> */}
        </Container>   
        {
            filterPhone !== null &&
            filterPhone.map(x => 
              <Container style={{marginTop:'40px'}} key={x.id} >                        
              <Row className='mb-3'>
                  <table className=" table-borderless">
                    <thead>
                      <tr>
                        <th  >
                          <div className='text-center'  >
                                 <Link to='/account'>
                                  {
                                    url != null ?
                                    <img               
                                   style={{width: '150px', height:150}}
                                   src={url}
                                   alt='..'  /> :
                                   <Spinner animation="border" role="status">
                                     <span className="visually-hidden">Loading...</span>
                                   </Spinner>
                                  }
                                 </Link>
                             </div>
                          </th>
                        <th  >
                          <div className="text-left" >
                             <h2 className="text-center">{todos[0] ? todos[0].businessname : ''}</h2>
                                <p className="text-center">{todos[0] ? todos[0].bstreet : ''}<br/>
                                {todos[0] ? todos[0].bcity : ''}<br/>{todos[0] ? todos[0].bphone : ''}</p>
                          </div>                         
                        </th>
                        <th  > 
                          <div className='text-left'>
                            <h3 >Invoice No:</h3><br></br>
                            <h3>{x.Idinvoice}</h3>
                          </div>
                        </th>
                        {/* <th ></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      <tr><td></td></tr>
                      <tr><td></td></tr>
                      <tr><td></td></tr>
    
                    </tbody>               
                  </table>
              </Row>
             
             
            <Row>
                <table className="table  table-sm ">
                <tbody>
                 <tr>
                  <td > <TextField fullWidth id="Name" name='Name' type='text' label="Full Name" variant="standard" readOnly value={x.Name} /></td>
                  <td ><TextField fullWidth  id="Phone" name='Phone' type='text'  label="Phone Number" variant="standard" readOnly value={x.Phone}  /></td>
                  <td> <TextField fullWidth  id="Address" name='Address' type='text' label="Address" variant="standard" readOnly value={x.Address} /></td>
                  <td><TextField fullWidth  id="City" name='City' type='text' label="City / State" variant="standard" readOnly value={x.City} /> </td>
                  <td><TextField fullWidth  id="Zip" name='Zip' type='number'  label="Zip-Code" variant="standard"  readOnly value={x.Zip} /></td>
                </tr>
                <tr>                  
                  <td><TextField fullWidth id="Year" name='Year' type='text' label="Year" variant="standard" readOnly value={x.Year} /></td>
                  <td><TextField fullWidth id="Make" name='Make' type='text' label="Make" variant="standard" readOnly value={x.Make} /></td> 
                  <td><TextField fullWidth id="Model" name='Model' type='text' label="Model" variant="standard" readOnly value={x.Model} /></td>
                  <td><TextField fullWidth id="Vin" name='Vin' type='text' label="VIN #" variant="standard" readOnly value={x.Vin} /></td>   
                  <td><TextField fullWidth id="Colors" name='Colors' type='text' label="Color" variant="standard" readOnly value={x.Color} /></td>                      
                </tr>
                <tr>
                  <td><TextField fullWidth id="Lic" name='Lic' type='text' label="License Plate Number" variant="standard" readOnly value={x.License} /></td>
                  <td><TextField fullWidth id="State" name='State' type='text' label="State" variant="standard" readOnly value={x.State} /></td>
                  <td><TextField fullWidth  id="Keyss" name='Keyss' type='text'  label="Keys" variant="standard"  readOnly value={x.Keyss} /></td>
                  <td><TextField fullWidth  id="Driver" name='Driver' type='text'  label="Driver" variant="standard"  readOnly value={x.Driver} /></td>
                  <td ><TextField fullWidth  id="Date1" name='Date1' type='datetime-local' label='Date'  variant="standard" readOnly value={x.Date1} /></td> 
                </tr> 
                <tr>
                  <td colSpan={2}> <TextField fullWidth id="Towedf" name='Towedf' type='text' label="Towed From" variant="standard" readOnly value={x.Towedf} /></td>
                  <td colSpan={2}><TextField fullWidth id="Towedt" name='Towedt' type='text' label="Truck Towed To" variant="standard" readOnly value={x.Towedt} /></td>
                  <td></td>
                </tr>
                </tbody>
                    </table>

                     <table className="table  table-sm ">            
                      <tbody>
                    <tr>
                      <th scope="row">Hook Up</th>
                      <td colSpan="2"><TextField multiline fullWidth  id="Hoop-text" name='Hoop-text' type='text'  variant="standard"  readOnly value={x.Hooptext} /></td>
                      <td><TextField fullWidth InputProps={{ disableUnderline: true }}    id="Hoop" name='Hoop' type='number'  variant="standard" readOnly value={x.Hoop} /></td>
                    </tr>
                    <tr>
                      <th scope="row">Mileage</th>
                      <td colSpan="2"><TextField multiline fullWidth id="Mileage-text" name='Mileage-text' type='text'  variant="standard"  readOnly value={x.Mileagetext} /></td>
                      <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Mileage" name='Mileage' type='number'  variant="standard" readOnly value={x.Mileage} /></td>
                    </tr>
                    <tr>
                      <th scope="row">Dollies</th>
                      <td colSpan="2"><TextField multiline fullWidth id="Dollies-text" name='Dollies-text' type='text'  variant="standard" readOnly value={x.Dolliestext} /> </td>
                      <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Dollies" name='Dollies' type='number'  variant="standard" readOnly value={x.Dollies} /></td>
                    </tr>                
                    <tr>
                      <th scope="row">Winch</th>
                      <td colSpan="2"><TextField multiline fullWidth id="Winch-text" name='Winch-text' type='text'  variant="standard" readOnly value={x.Winchtext} /></td>
                      <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Winch" name='Winch' type='number'  variant="standard" readOnly value={x.Winch} /></td>
                    </tr>
                    <tr>
                      <th scope="row">Lock outs</th>
                      <td colSpan="2"><TextField multiline fullWidth id="Lock-text" name='Lock-text' type='text'  variant="standard"  readOnly value={x.Locktext} /></td>
                      <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Lock" name='Lock' type='number'  variant="standard" readOnly value={x.Lock} /></td>
                    </tr>
                    <tr>
                      <th scope="row">Gas delivery</th>
                      <td colSpan="2"><TextField multiline fullWidth id="Gas-text" name='Gas-text' type='text'  variant="standard"  readOnly value={x.Gastext} /></td>
                      <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Gas" name='Gas' type='number'  variant="standard" readOnly value={x.Gas} /></td>
                    </tr>
                    <tr>
                      <th scope="row">Clean Up</th>
                      <td colSpan="2"><TextField multiline fullWidth id="Clean-text" name='Clean-text' type='number'  variant="standard" readOnly value={x.Cleantext} /></td>
                      <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Clean" name='Clean' type='number'  variant="standard" readOnly value={x.Clean} /></td>
                    </tr>
                    <tr> 
                  <th scope="row">Dispatch</th>
                     <td colSpan="2"><TextField multiline fullWidth id="Dispatch-text" name='Dispatch-text' type='number'  variant="standard" readOnly value={x.Dispatchtext} /></td>
                     <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Dispatch" name='Dispatch' type='number'  variant="standard" readOnly value={x.Dispatch} /></td>
                   </tr>
                   <tr> 
                     <th scope="row">Gate</th>
                     <td colSpan="2"><TextField multiline fullWidth id="Gate-text" name='Gate-text' type='number'  variant="standard" readOnly value={x.Gatetext} /></td>
                     <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Gate" name='Gate' type='number'  variant="standard" readOnly value={x.Gate} /></td>
                   </tr>
                   <tr> 
                     <th scope="row">2nd Tow</th>
                     <td colSpan="2"><TextField multiline fullWidth id="Secondtown-text" name='Secondtown-text' type='number'  variant="standard" readOnly value={x.Secondtowtext} /></td>
                     <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Secondtow" name='Secondtow' type='number'  variant="standard" readOnly value={x.Secondtow} /></td>
                   </tr>
                   <tr> 
                     <th scope="row">2nd Towed Mileage</th>
                     <td colSpan="2"><TextField multiline fullWidth id="Secondtownmilles-text" name='Secondtownmilles-text' type='text'  variant="standard" readOnly value={x.Secondtowmillestext} /></td>
                     <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Secondtowmilles" name='Secondtowmilles' type='number'  variant="standard" readOnly value={x.Secondtowmilles} /></td>
                   </tr>
                    <tr>
                      <th scope="row">Stored Days</th>
                      <td colSpan="2"><TextField multiline fullWidth id="Store-text" name='Store-text' type='text'  variant="standard" readOnly value={x.Storetext} /> </td>
                      <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Store" name='Store' type='number'  variant="standard" readOnly value={x.Store} /></td>
                    </tr>
                    <tr> 
                      <th scope="row">Jump Start</th>
                      <td colSpan="2"><TextField multiline fullWidth id="jump-start-description" name='jump-start-description' type='text'  variant="standard" readOnly value={x.JumpStarttext}  /></td>
                      <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Jump-Start" name='Jump-Start' type='number'  variant="standard" readOnly value={x.JumpStart}  /></td>
                    </tr>
                    <tr>
                      <th scope="row">Other Charges</th>
                      <td colSpan="2"><TextField multiline fullWidth id="Other-text" name='Other-text' type='text' label="" variant="standard"readOnly value={x.Othertext} /></td>
                      <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Other" name='Other' type='number' label="" variant="standard" readOnly value={x.Other} /></td>
                    </tr>
                    <tr>
                      <th scope="row" colSpan="3" className='text-center'>Amount Due</th>
                      <th> 
                        {
                         ` $ ${x.Total}`
    
                        }
                        {/* <TextField fullWidth id="Total" name='Total' type='text' label="Total" variant="standard" onChange={myChangeHandlersettotal} /> */}
                      </th>                  
                    </tr>
                    <tr>
                  <th scope="row" colSpan="5" className='text-left'><small>{`I authorized ${todos[0] ? todos[0].businessname : ''} to tow the above listed vehicle. As per Ors. 98.805 - 98.918`}</small></th>
                </tr>
                <tr>
                  <th scope="row" colSpan="5" className='text-left'><small>I, the  undersigned, do hereby certify thet I am legally authorized and entitled to take possesion of the vehicle described above and all personal property therein.</small></th>
                 
                </tr>
                <tr>
                  <th scope="row" colSpan="3" className='text-left'>Customer Signature:</th>
                  <td><TextField fullWidth  id="SignDate" name='SignDate' type='datetime-local'  variant="standard" /></td>
                </tr>                
                
                <tr>
                  <th scope="row" colSpan="5" className='text-center'>Police Hold- Mark Prior Damage</th>
                </tr>
                    <tr>
                      <td className='text-center'>
                      <div className='text-center'  >
                                 <Link to='/account'>
                                  {
                                    front1  ?
                                    <img               
                                   style={{width: '150px', height:80}}
                                   src={front1}
                                   alt='..'  /> :
                                   <Spinner animation="border" role="status">
                                     <span className="visually-hidden">Loading...</span>
                                   </Spinner>
                                  }
                                 </Link>
                             </div>
                      </td>
                      <td className='text-center'>
                            <div className='text-center'  >
                                 <Link to='/account'>
                                  {
                                    rear1  ?
                                    <img               
                                   style={{width: '150px', height:80}}
                                   src={rear1}
                                   alt='..'  /> :
                                   <Spinner animation="border" role="status">
                                     <span className="visually-hidden">Loading...</span>
                                   </Spinner>
                                  }
                                 </Link>
                             </div>
                      </td>
                      <td className='text-center'>
                            <div className='text-center'  >
                                 <Link to='/account'>
                                  {
                                    right1  ?
                                    <img               
                                   style={{width: '150px', height:80}}
                                   src={right1}
                                   alt='..'  /> :
                                   <Spinner animation="border" role="status">
                                     <span className="visually-hidden">Loading...</span>
                                   </Spinner>
                                  }
                                 </Link>
                             </div>
                      </td>
                      <td className='text-center'>
                            <div className='text-center'  >
                                 <Link to='/account'>
                                  {
                                    left1  ?
                                    <img               
                                   style={{width: '150px', height:80}}
                                   src={left1}
                                   alt='..'  /> :
                                   <Spinner animation="border" role="status">
                                     <span className="visually-hidden">Loading...</span>
                                   </Spinner>
                                  }
                                 </Link>
                             </div>
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'><TextField fullWidth  id="Front" name='Front' type='text'  variant="standard" readOnly value={x.Front}  /></td>
                      <td className='text-center'><TextField fullWidth  id="Rear" name='Rear' type='text'  variant="standard" readOnly value={x.Rear}  /></td>
                      <td className='text-center'><TextField fullWidth  id="Right" name='Right' type='text'  variant="standard" readOnly value={x.Right}  /></td>
                      <td className='text-center'><TextField fullWidth  id="Left" name='Left' type='text'  variant="standard" readOnly value={x.Left}  /></td>
                    </tr>                
                  </tbody>
                </table>
            </Row>
            <Row className='mt-0'>
             <Col md={4} className='text-center' >
                 <span><strong>{todos[0] ? todos[0].bwebsite : ''}</strong></span>
             </Col>
             <Col md={4} className='text-center'>
                 <h5 ><strong>Thanks for choosing us.</strong></h5>
             </Col>
             <Col md={4} >
             
             </Col>
         </Row>
         <Row>
                 <Col >
                    {/* <Button                                
                         type="button"
                          variant="outlined"
                          color="primary"
                          size="small"
                          className="primary"
                          startIcon={<SaveIcon />}   
                          onClick={authentication}               
                         >
                          Save
                     </Button> */}
                 </Col>
                 <Col className='text-right' >
                     <Button    
                            onClick={print}  
                             variant="outlined"
                             color="secondary"
                             size="small"
                             className="primary"
                             startIcon={<PrintIcon />}                  
                            >
                             Print
                        </Button>
                 </Col>

               </Row>
           
          </Container>
            )
        }
        </> 
        
    )
}

export default SearchInvoice