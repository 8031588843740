import React from 'react'
import Invoice from "../../models/Invoice"
export const SET_INVOICES = 'SET_INVOICES'
export const SET_MAKES = 'SET_MAKES'
export const SET_MODELS = 'SET_MODELS'
export const CREATE_INVOICE = 'CREATE_INVOICE'
export const UPDATE_INVOICE = 'CREATE_INVOICE'



export const createInvoice = (user, data) => {
 
  return async dispatch => {
      //any async code you want!
      // const user = useSelector(selectUser)
     await fetch(`${process.env.REACT_APP_BASE_URL}${user}/${process.env.REACT_APP_CREATE_INVOICE}/${user}/data.json`, 
     {
       method:"POST",
       headers: {"content-type": "application/json"
     },
     body: JSON.stringify(data)
     })
     .then(() => 
         alert("Successfully updated")
         )
       .catch(() => 
          alert("Error updating field")
       )

      dispatch({ 
          type: CREATE_INVOICE, 
          invoice: data
      })    
  }
}

export const updateInvoice = (user, item, data) => {
  return async dispatch => {
    try {
      // Reconstruimos la URL usando variables de entorno simplificadas
      const url = `${process.env.REACT_APP_BASE_URL}${user}/${process.env.REACT_APP_CREATE_INVOICE}/${user}/data/${item}.json`;
     

      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error(`Error al actualizar el campo: ${response.status}`);
      }

      alert("Successfully updated");

      dispatch({
        type: UPDATE_INVOICE,
        item,
        data
      });
    } catch (error) {
      console.error("Error actualizando:", error.message);
      alert(`Error updating field: ${error.message}`);
    }
  };
};
export const fetchInvoices = (user) => {  
   
    return async dispatch => {   
      
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}${user}/${process.env.REACT_APP_CREATE_INVOICE}/${user}/data.json`);
      
           if (!response.ok){
               throw new Error('Something went wrong!')
           }
      
           const resData = await response.json()
           const loadedInvoices = [];
             for(const key in resData) {
                loadedInvoices.push(new Invoice(
                     key,  
                     resData[key].Idinvoice,
                     resData[key].Name,
                     resData[key].Phone,
                     resData[key].Address,
                     resData[key].City,
                     resData[key].Zip,
                     resData[key].Keyss,
                     resData[key].Driver,
                     resData[key].Date1,
                     resData[key].Year,
                     resData[key].Make,
                     resData[key].Model,
                     resData[key].Vin,
                     resData[key].Color,
                     resData[key].License,
                     resData[key].State,
                     resData[key].Towedf,
                     resData[key].Towedt,
                     resData[key].Hoop,
                     resData[key].Mileage,
                     resData[key].Dollies,
                     resData[key].Winch,
                     resData[key].Lock,
                     resData[key].Gas,
                     resData[key].Clean,
                     resData[key].Dispatch,
                     resData[key].Gate,
                     resData[key].Secondtow,
                     resData[key].Secondtowmilles,
                     resData[key].Store,
                     resData[key].Jumpstart,
                     resData[key].Other,
                     resData[key].Total,
                     resData[key].Front,
                     resData[key].Rear,
                     resData[key].Right,
                     resData[key].Left,
                     resData[key].Date2,
                     resData[key].Payment,
                     resData[key].Hooptext,
                     resData[key].Mileagetext,
                     resData[key].Dolliestext,
                     resData[key].Winchtext,
                     resData[key].Locktext,
                     resData[key].Gastext,
                     resData[key].Cleantext,
                     resData[key].Dispatchtext,
                     resData[key].Gatetext,
                     resData[key].Secondtowtext,
                     resData[key].Secondtowmillestext,
                     resData[key].Storetext,
                     resData[key].Jumpstarttext,
                     resData[key].Othertext,
                     ))
             }
          dispatch({ type:SET_INVOICES, invoices: loadedInvoices });
        } catch (err) {
          throw err;
        }
         
        } 
}


export const setMakes = items => {
    return {
        type: SET_MAKES, makes: items
    }
}
export const setModels = item => {
    return {
        type: SET_MODELS, models: item
    }
}