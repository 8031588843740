import React from 'react'
import './HeaderBlock.css'

function HeaderBlock() {
  return (    
    <header>
    <div className="overlay" style={{ backgroundImage: "url('https://kidtowing.com/wp-content/uploads/2022/03/pic-138-accident.png')", backgroundSize: "cover" }}>
      {/* Imagen de fondo aplicada con CSS */}
    </div>
  
    {/* Video y otros contenidos */}
    <div className="container h-100">
      <div className="d-flex h-100 text-center align-items-center">
        <div className="w-100 text-white">
          <h1 className="display-3">You imagine impossible.</h1>
          <h1 className="display-3">We build impossible.</h1>
        </div>
      </div>
    </div>
  </header>
  
  
  
  
  )
}

export default HeaderBlock

