import React, {useState, useEffect} from 'react'
import { Container, Row, Col, Spinner  } from 'react-bootstrap'
import Axios from 'axios'
import {selectUser } from '../auth/users/userSlice'
import { useSelector } from 'react-redux'
import firebase from "firebase";
import { db } from '../auth/firebase'
import { Link } from 'react-router-dom';
import './FormTowing.css'
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@material-ui/icons/Save';
import front1 from '../assets/image/front.png'
import rear1 from '../assets/image/rear.png'
import right1 from '../assets/image/right.png'
import left1 from '../assets/image/left.png'

 
const  FormTowing = (props) => { 


  const user = useSelector(selectUser)
  const [url, setUrl] = useState(null);
  const [todos, setTodos] = useState([]);

  const getProfileImage = () => {
    // firebase.storage().ref(`users/${user.uid}/cropped-GottaWebDesign-02-6-scaled-345x219-1-160x93.jpg`).getDownloadURL().then(imgUrl => {setUrl(imgUrl)})
    firebase
    .storage()
    .ref('users')
    .child(user.uid + "/DMV-LOGO.png")
    .getDownloadURL()
    .then((imgUrl) => {
      setUrl(imgUrl);
    });
  }

  useEffect(() => {
    setTimeout(() => {
      getProfileImage();
    }, 1000);
  }, [])



 // Get Todos
 const  getTodos = () => {
   db.collection(user.uid).onSnapshot(function(querySnapshot) {
     setTodos(
       querySnapshot.docs.map((doc) => ({
         id: doc.id,
         bcity: doc.data().bcity,
         bemail: doc.data().bemail,
         bstreet: doc.data().bstreet,
         businessname: doc.data().businessname,
         bphone: doc.data().bphone,
         bwebsite: doc.data().bwebsite
       }))
     );
   });
 }

 useEffect(() => {
  getTodos();    
}, []) // blank to run only on first time


const[ultinvoice, setUltinvoice] = useState(null)
const ulimoinv = useSelector(state => state.invoices.invoices)

useEffect(() => {
  const rel =  ulimoinv[ulimoinv.length - 1]
  setUltinvoice(rel)
},[ulimoinv])



  const [idinvoice, setidinvoice] = useState('')
  const [name,      setname] = useState('')
  const [phone,     setphone] = useState('')
  const [address,   setaddress] = useState('')
  const [city,      setcity] = useState('')
  const [zip,       setzip] = useState('')
  const [keyss,     setkeyss] = useState('')
  const [driver,    setdriver] = useState('') 
  const [date1,      setdate1] = useState('')
  
  const [year,       setyear] = useState('')
  const [make,       setmake] = useState('')
  const [model,      setmodel] = useState('')
  const [vin,      setvin] = useState('')
  const [color,      setcolor] = useState('')
  const [license,    setlicense] = useState('')
  const [state,      setstate] = useState('')

  const [towedf,    settowedf] = useState('')
  const [towedt,    settowedt] = useState('')
  const [hoop,      sethoop] = useState('')
  const [mileage,   setmileage] = useState('')
  const [dollies,   setdollies] = useState('')
  const [winch,     setwinch] = useState('')
  const [lock,      setlock] = useState('')
  const [gas,       setgas] = useState('')
  const [clean,     setclean] = useState('')

  const [dispatch,     setdispatch] = useState('')
  const [gate,     setgate] = useState('')
  const [secondtow,     setsecondtow] = useState('')
  const [secondtowmilles,     setsecondtowmilles] = useState('')

  const [store,     setstore] = useState('')
  const [other,     setother] = useState('')
  const [total,     settotal] = useState('')
  const [front,     setfront] = useState('')
  const [rear,      setrear] = useState('')
  const [right,     setright] = useState('')
  const [left,      setleft] = useState('')
  const [date2,     setdate2] = useState('')
  const [payment,   setpayment] = useState('')

  const [jumpstart,   setjumpstart] = useState('')

  const [hooptext,      sethooptext] = useState('')
  const [mileagetext,   setmileagetext] = useState('')
  const [dolliestext,   setdolliestext] = useState('')
  const [winchtext,     setwinchtext] = useState('')
  const [locktext,      setlocktext] = useState('')
  const [gastext,       setgastext] = useState('')
  const [cleantext,     setcleantext] = useState('')
  const [dispatchtext,     setdispatchtext] = useState('')
  const [gatetext,     setgatetext] = useState('')
  const [storetext,     setstoretext] = useState('')
  const [othertext,     setothertext] = useState('')
  const [secondtowtext,     setsecondtowtext] = useState('')
  const [secondtowmillestext,     setsecondtowmillestext] = useState('')
  const [jumpstarttext, setjumpstarttext] = useState('')


    
    //  function myChangeHandlerbalance() { 
       
    //   setbalance(
        
    //       ((((pri1 ?  parseInt(pri1) * parseInt(qua1) : 0) ) + 
    //         ((pri2 ?  parseInt(pri2) * parseInt(qua2) : 0) ) +
    //         ((pri3 ?  parseInt(pri3) * parseInt(qua3) : 0) ) + 
    //         ((pri4 ?  parseInt(pri4) * parseInt(qua4) : 0) ) + 
    //         ((pri5 ?  parseInt(pri5) * parseInt(qua5) : 0) ) + 
    //         ((pri6 ?  parseInt(pri6) * parseInt(qua6) : 0) )) - (amo ? parseInt(amo) : 0)).toFixed(2)
    //   )
    
    // }
     

     function myChangeHandlersetidinvoice(event) { setidinvoice(event.target.value)}
     function myChangeHandlersetname(event) { setname(event.target.value)}     
     function myChangeHandlersetphone(event) { setphone(event.target.value)}      
     function myChangeHandlersetaddress(event) { setaddress(event.target.value)}
     function myChangeHandlersetcity(event) { setcity(event.target.value)}
     function myChangeHandlersetzip(event) { setzip(event.target.value)}    
     function myChangeHandlersetkeyss(event) { setkeyss(event.target.value)}
     function myChangeHandlersetdriver(event) { setdriver(event.target.value)}
     function myChangeHandlersetdate1(event) {  setdate1(event.target.value)} 

     function myChangeHandlersetyear(event) {  setyear(event.target.value)} 
     function myChangeHandlersetmake(event) {  setmake(event.target.value)} 
     function myChangeHandlersetmodel(event) {  setmodel(event.target.value)} 
     function myChangeHandlersetvin(event) {  setvin(event.target.value)} 
     function myChangeHandlersetcolor(event) {  setcolor(event.target.value)} 
     function myChangeHandlersetlicense(event) {  setlicense(event.target.value)} 
     function myChangeHandlersetstate(event) {  setstate(event.target.value)} 

     function myChangeHandlersettowedf(event) { settowedf(event.target.value)}
     function myChangeHandlersettowedt(event) { settowedt(event.target.value)}
     function myChangeHandlersethoop(event) { sethoop(event.target.value)} 
     function myChangeHandlersetmileage(event) { setmileage(event.target.value)}
     function myChangeHandlersetdollies(event) { setdollies(event.target.value)}
     function myChangeHandlersetwinch(event) { setwinch(event.target.value)}  
     function myChangeHandlersetlock(event) { setlock(event.target.value)}
     function myChangeHandlersetgas(event) { setgas(event.target.value)}
     function myChangeHandlersetclean(event) { setclean(event.target.value)}  

     function myChangeHandlersetdispatch(event) { setdispatch(event.target.value)}  
     function myChangeHandlersetgate(event) { setgate(event.target.value)}  
     function myChangeHandlersetsecondtow(event) { setsecondtow(event.target.value)}  
     function myChangeHandlersetsecondtowmilles(event) { setsecondtowmilles(event.target.value)}  

     function myChangeHandlersetstore(event)  { setstore(event.target.value)}

     function myChangeHandlersetjumpstart(event)  { setjumpstart(event.target.value)}


     function myChangeHandlersetother (event)  { setother(event.target.value)}
     function myChangeHandlersettotal(event)  { settotal(event.target.value)}
     function myChangeHandlersetfront(event)  { setfront(event.target.value)}
     function myChangeHandlersetrear(event)  { setrear(event.target.value)}
     function myChangeHandlersetright(event)  { setright(event.target.value)}
     function myChangeHandlersetleft(event)  { setleft(event.target.value)}
     function myChangeHandlersetdate2(event)  { setdate2(event.target.value)}
     function myChangeHandlersetpayment(event)  { setpayment(event.target.value)}
     function myChangeHandlersethooptext(event)  { sethooptext(event.target.value)}
     function myChangeHandlersetmileagetext(event)  { setmileagetext(event.target.value)}
     function myChangeHandlersetdolliestext(event)  { setdolliestext(event.target.value)}
     function myChangeHandlersetwinchtext(event)  { setwinchtext(event.target.value)}
     function myChangeHandlersetlocktext(event)  { setlocktext(event.target.value)}
     function myChangeHandlersetgastext(event)  { setgastext(event.target.value)}
     function myChangeHandlersetcleantext(event)  { setcleantext(event.target.value)}

     function myChangeHandlersetdispatchtext(event) { setdispatchtext(event.target.value)}  
     function myChangeHandlersetgatetext(event) { setgatetext(event.target.value)}  
     function myChangeHandlersetsecondtowtext(event) { setsecondtowtext(event.target.value)}  
     function myChangeHandlersetsecondtowmillestext(event) { setsecondtowmillestext(event.target.value)}  

     function myChangeHandlersetstoretext(event)  { setstoretext(event.target.value)}

     function myChangeHandlersetjumpstarttext(event)  { setjumpstarttext(event.target.value)}

     function myChangeHandlersetothertext(event)  { setothertext(event.target.value)}

     function print(){
         window.print()
       }


       const authentication = () => {          
 
       const data = {
          "Idinvoice": ultinvoice != null ? parseInt(ultinvoice.Idinvoice) + 1 : '',
          "Name": name,    
          "Phone": phone,   
          "Address": address, 
          "City": city,    
          "Zip": zip,     
          "Keyss": keyss,   
          "Driver": driver,  
          "Date1": date1,   
          "Year": year,   
          "Make": make,   
          "Model": model,   
          "Vin": vin,   
          "Color": color,   
          "License": license,   
          "State": state,   
          "Towedf": towedf,  
          "Towedt": towedt,  
          "Hoop": hoop,    
          "Mileage": mileage, 
          "Dollies": dollies, 
          "Winch": winch,   
          "Lock": lock,    
          "Gas": gas,     
          "Clean": clean,   
          "Dispatch": dispatch,   
          "Gate": gate,   
          "Secondtow": secondtow,   
          "Secondtowmilles": secondtowmilles,   
          "Store": store,   
          "Jumpstart": jumpstart,   
          "Other": other,   
          "Total": ((((hoop ? parseFloat(hoop) : 0) ) + 
                        ((mileage ?  parseFloat(mileage) : 0) ) +
                        ((dollies ?  parseFloat(dollies) : 0) ) + 
                        ((winch ?  parseFloat(winch) : 0) ) + 
                        ((lock ?  parseFloat(lock) : 0) ) + 
                        ((gas ?  parseFloat(gas) : 0) )) +
                        ((clean ?  parseFloat(clean) : 0) ) + 
                        ((dispatch ?  parseFloat(dispatch) : 0) ) + 
                        ((gate ?  parseFloat(gate) : 0) ) + 
                        ((secondtow ?  parseFloat(secondtow) : 0) ) + 
                        ((secondtowmilles ?  parseFloat(secondtowmilles) : 0) ) + 
                        ((store ?  parseFloat(store) : 0) ) + 
                        ((jumpstart ?  parseFloat(jumpstart) : 0) ) + 
                        ((other ?  parseFloat(other) : 0) ) ).toFixed(2),  
          "Front": front,   
          "Rear": rear,    
          "Right": right,   
          "Left": left,    
          "Date2": date2,   
          "Payment": payment,           
          "Hooptext": hooptext,  
          "Mileagetext": mileagetext,
          "Dolliestext": dolliestext,
          "Winchtext": winchtext,
          "Locktext": locktext,  
          "Gastext": gastext,  
          "Cleantext": cleantext, 
          "Dispatchtext": dispatchtext, 
          "Gatetext": gatetext, 
          "Secondtowtext": secondtowtext, 
          "Secondtowmillestext": secondtowmillestext, 
          "Storetext": storetext,
          "Jumpstarttext": jumpstarttext,
          "Othertext": othertext
        
      }  

      Axios.post(`${process.env.REACT_APP_BASE_URL}${user.uid}/${process.env.REACT_APP_CREATE_INVOICE}/${user.uid}/data.json`, data)
        .then(r => {
          localStorage.setItem('token', r.data.token)
          window.location = "/"
        }).then(() => alert('The Invoice has been succesfully saved'))
        .catch(e => {
          alert('error al iniciar sesion')
        } )
      // console.log(data)
      }

     
    return (
      
        <Container style={{ width: '240mm', minHeight: '297mm', padding: '0', backgroundColor: '#fff', border: '1px solid #000' }}>
      <Row className='m-0'>
        <table className="table-borderless w-100">
          <thead>
            <tr>
              <th>
                <div className='text-left' >
                  <Link to='/account'>
                    {
                      url != null ? (
                        <img
                          style={{ width: '130px', height: '80px' }}
                          src={url}
                          alt='..'
                        />
                      ) : (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      )
                    }
                  </Link>
                </div>
              </th>
              <th>
                <div className="text-center pt-3">
                  <h4>CERTIFICATE OF POSSESSORY LIEN FORECLOSURE</h4>
                  <h5>(ORS 98.830 - TOWING ABANDONED VEHICLE FROM PRIVATE PROPERTY)</h5>
                  <spam className="text-justify" >
                    (You may obtain copies of laws at your local library or at www.oregonlegislature.gov/bills_laws/Pages/ORS.aspx)
                  </spam>
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </Row>

      <Row className='m-0'>
        <table className="table-borderless w-100">
          <thead>
          <tr>
  <th>
    <div>
      <p
        style={{
          fontSize: '16px',
          fontFamily: 'Arial',
          textAlign: 'justify',
          whiteSpace: 'pre-wrap', // Cambiar a pre-wrap
          margin: '0',
          padding: '10px',
        }}
        className="pl-2 pr-2 pt-2"
      >
        This certification is for use in support of Oregon title applications where transfer results from the foreclosure
        of a lien created under ORS 98.830 as the result of an abandoned vehicle being removed from private property at the request of the owner or lawful possessor of the property.
      </p>
      <p
        style={{
            fontSize: '16px',
            fontFamily: 'Arial',
            textAlign: 'justify',
            whiteSpace: 'pre-wrap', // Cambiar a pre-wrap
            margin: '0',
            padding: '10px',
          }}
        className="pl-2 pr-2 pt-2 pb"
      >
        ORS 822.093 requires lien claimants to keep records sufficient to establish that all vehicles being sold or offered for sale 
        were acquired by the lien claimant as the result of a possessory lien. Records, including a signed and dated copy of this
        DMV certificate of possessory lien foreclosure form, must be retained for a period of five years by the lien claimant. Failure to maintain lien records may result 
        in a civil penalty of up to $1,000 per violation.
      </p>
    </div>
  </th>
</tr>


            {/* <tr>
              <th>
                <div className=''>
                  <p style={{ fontSize: '16px', fontFamily: 'Arial', textAlign: 'justify', whiteSpace: 'pre-line' }} className="pl-2 pr-2 pt-2">
                    This certification is for use in support of Oregon title applications where transfer results from the foreclosure <br/> of a lien created 
                    under ORS 98.830 as the result of an abandoned vehicle being removed from private property at the request of the owner or lawful possessor of the property.
                  </p>
                  <h5 className="text-justify pl-2 pr-2 pt-2 pb">
                    ORS 822.093 requires lien claimants to keep records sufficient to establish that all vehicles being sold or offered for sale 
                    were acquired by the lien claimant as the result of a possessory lien. Records, including a signed and dated copy of this
                    DMV certificate of possessory lien foreclosure form, mus-2t be retained for a period of five years by the lien claimant. Failure to maintain lien records may result 
                    in a civil penalty of up to $1,000 per violation.
                  </h5>
                </div>
              </th>
            </tr> */}
          </thead>
        </table>
      </Row>

      <Row className='m-0'>
        <table className="table-borderless w-100">
          <thead>
            <tr>
              <th className=' text-center pl-3 pr-3'>
                <h4 style={{
                     fontFamily: 'Arial'}} className='text-left pt-3'>I certify that:</h4>
                <ul style={{
                     fontFamily: 'Arial'}} className='text-justify fs-5  pr-3'>
                  <li>I, or the firm I represent, at the request of the owner or lawful possessor of private property, removed the following described vehicle from said property, as provided in ORS 98.830.</li>
                  <li>I foreclosed the possessory lien in accordance with ORS 98.812(2), ORS 98.854, ORS 87.172(3) and ORS 87.176 to 87.206.</li>
                  <li>I complied with all applicable statutory requirements, including notification of all security interest holders as listed on the certificate of title, in accordance with ORS 87.196.</li>
                  <li>The vehicle described below was sold at public auction to:</li>
                </ul>

                <h2 className='text-center'>Buyer's name and date of auction required.</h2>
                <div className='text-center'>
                <div className="col-2"></div>
                <div className='col-8 text-center mx-auto'>
                <div className="input-group mb-3 mt-3 d-flex justify-content-center">
                  <input type="text" className="form-control underline-input" style={{ fontSize: '1rem' }} placeholder="Buyer's printed name" />
                  <div className="input-group-append">
                  <span className="transparent-span align-center" id="basic-addon2">(Buyer's printed name)</span>
                </div>                          
                </div>
                <div className="input-group mb-3 d-flex justify-content-center">
                  <input type="text" className="form-control underline-input" style={{ fontSize: '1rem' }} placeholder="Location of auction" />
                  <div className="input-group-append">
                    <span className="transparent-span align-center" id="basic-addon3">(Location of auction)</span>
                  </div>                           
                </div>
                <div className="input-group mb-3 d-flex justify-content-center">
                  <input type="text" className="form-control underline-input" style={{ fontSize: '1rem' }} placeholder="Date of auction" />
                  <div className="input-group-append align-center">
                    <span className="transparent-span" id="basic-addon3">(Date of auction)</span>
                  </div>     
                </div>
                <div className="col-2"></div>
                </div>
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </Row>

      <Row className='m-0 p-0'>
        <table className="table table-sm">
          <tbody>
            <tr>
              <td scope="row" colSpan="2" style={{ width: '33%' }} >
              <div className="d-flex">
                <TextField 
                  fullWidth 
                  id="Lic" 
                  name="Lic" 
                  type="text" 
                  label="PLATE NUMBER" 
                  variant="standard" 
                  onChange={myChangeHandlersetlicense} 
                  />
                 </div>
                </td>
              <td scope="row"  >
                <TextField 
                fullWidth 
                id="State" 
                name='State' 
                type='text' 
                label="STATE OF ISSUE" 
                variant="standard" 
                onChange={myChangeHandlersetstate} 
                InputLabelProps={{
                    style: { fontSize: '0.75rem' } // Ajusta el tamaño de la fuente aquí
                  }} />
                </td>
              <td scope="row"  ><TextField fullWidth id="Year" name='Year' type='text' label="VEHICLE YEAR" variant="standard" onChange={myChangeHandlersetyear} /></td>
              <td scope="row"  ><TextField fullWidth id="Make" name='Make' type='text' label="MAKE" variant="standard" onChange={myChangeHandlersetmake} /></td>
              <td scope="row"  ><TextField fullWidth id="Model" name='Model' type='text' label="BODY STYLE" variant="standard" onChange={myChangeHandlersetmodel} /></td>
              <td scope="row"  ><TextField fullWidth id="Model" name='Model' type='text' label="MODEL" variant="standard" onChange={myChangeHandlersetmodel} /></td>
            </tr>
            <tr>
              <td colSpan="7"><TextField fullWidth id="Vin" name='Vin' type='text' label="VEHICLE IDENTIFICATION NUMBER" variant="standard" onChange={myChangeHandlersetvin} /></td>
            </tr>
            <tr>
            <td colSpan="7">
               <h5 className="text-justify pl-5 pr-5">
                   I (the lien claimant) further certify that all information given is true and accurate and thet I am 
                   making this certificationin support of an application for title. I understand it is a crime under ORS 803.075
                   to certify the truth of a statement when I know the stattement is not true. Such a crime is a class A misdemeanor
                   punishable by a jall sentence of up to 1 year and a fine of up to $6,250, or both.     
               </h5>
            </td>
            </tr>
            <tr>
              <td colSpan='5'>
                <TextField 
                fullWidth 
                id="Name" 
                name='Name' 
                type='text' 
                label="PRINTED NAME OF LIEN CLAIMANT (A TOWER MUST HAVE A VALID TOW BUSSINESS CERTIFICATE ISSUED UNDER ORS 822.205)" 
                variant="standard" 
                onChange={myChangeHandlersetname} 
                InputLabelProps={{
                    style: { fontSize: '0.75rem' } // Ajusta el tamaño de la fuente aquí
                  }}/>
                </td>
              <td colSpan='2' ><TextField fullWidth id="Phone" name='Phone' type='text' label="Phone Number" variant="standard" onChange={myChangeHandlersetphone} /></td>
            </tr>
            <tr>
              <td colSpan='3'><TextField fullWidth id="Keys" name='Keys' type='text' label="STREET ADDRESS" variant="standard" onChange={myChangeHandlersetkeyss} /></td>
              <td colSpan='2'style={{ width: '30%' }} ><TextField fullWidth id="TowedFrom" name='TowedFrom' type='text' label="CITY" variant="standard" onChange={myChangeHandlersettowedf} /></td>
              <td colSpan='1' ><TextField fullWidth id="VIN" name='VIN' type='text' label="STATE" variant="standard" onChange={myChangeHandlersetvin} /></td>
              <td colSpan='1' ><TextField fullWidth id="Date" name='text' type='text' label='ZIP CODE' variant="standard" onChange={myChangeHandlersetdate1} /></td>
            </tr>
            <tr>
              <td scope="row" colSpan="4">
                <TextField 
                fullWidth 
                id="Driver" 
                name='Driver' 
                type='text' 
                label="SIGNATURE OF LIEN CLAIMANT" 
                variant="standard" 
                onChange={myChangeHandlersetdriver}
                defaultValue="X"
                InputProps={{
                    style: { fontWeight: 'bold', textTransform: 'uppercase', fontSize: '1.2rem' }
                  }}
                 /></td>
              <td scope="row" colSpan="1" >
                <TextField 
                fullWidth 
                id="State" 
                name='State' 
                type='text' 
                label="DATE" 
                variant="standard" 
                onChange={myChangeHandlersetstate}
                InputLabelProps={{
                    style: { fontSize: '0.75rem' } // Ajusta el tamaño de la fuente aquí
                  }} /></td>
              <td scope="row" colSpan="2">
                <TextField 
                fullWidth 
                id="Keys2" 
                name='Keys2' 
                type='text' 
                label="TOWER PLATE NUMBER(TW PLATE)" 
                variant="standard" 
                onChange={myChangeHandlersetkeyss}
                InputLabelProps={{
                    style: { fontSize: '0.75rem' } // Ajusta el tamaño de la fuente aquí
                  }}
                 />
                </td>
            </tr>
          </tbody>
        </table>
      </Row>

      <Row className='m-0 p-0'>
    <table className="table table-sm">
        <tbody>
            <tr className='text-center'>
                <td colSpan="5"> {/* Asegúrate de usar una celda de tabla */}
                    <div className='border text-center bordered-box' style={{ 
                        border: '3px solid black', /* Borde negro normal */
                        borderBottom: '6px solid black', /* Borde inferior más grueso */
                        borderRight: '6px solid black', /* Borde derecho más grueso */
                        padding: '10px', /* Reducción del espaciado interno */
                        backgroundColor: 'white' /* Color de fondo para hacer visible el rectángulo */
                    }}>
                        <h2 className='font-weight-bold' style={{ fontSize: '1.8rem', fontWeight: '800' }}>COMPLETE IN FULL AND SIGN.</h2> {/* Tamaño de fuente más pequeño */}
                        <h5 style={{ margin: '0.5rem 0' }}>This form must be submitted to DMV with the application for title.</h5> {/* Margen reducido */}
                    </div>
                </td>
            </tr>
            <tr>                     
            </tr>
        </tbody>
    </table>
</Row>




      {/* <Row className='m-0 p-0' >
            <table className="table table-sm">
                <tbody>
                <tr className='text-center'>
                    <div className='border text-center  bordered-box'>
                    <div className='border text-center  '>
                        <h2 className='text-bold'>COMPLETE IN FULL AND SIGN.</h2>
                        <h5>This form must be submitted to DMV with the application for title.</h5>
                    </div>
                    </div>
                 </tr>
                 <tr>                     
                 </tr>
                </tbody>
            </table>
            
      </Row> */}
              <div className="text-white mt-0">
            <div className="d-flex justify-content-between">
                <span className="bg-black pl-1 border-none print-bg">735-519 (1-18)</span>
                <span className="bg-black pr-1 border-none print-bg">735-519 (1-18)</span>
            </div>
        </div>

        {/* <div className="text-white mt-0">
             <div className="d-flex justify-content-between">
               <span className="bg-black pl-1 border-none">735-519 (1-18)</span>
               <span className="bg-black pr-1 border-none">735-519 (1-18)</span>
             </div>
        </div> */}

      
    </Container>

    //     <Container style={{marginTop:'10px'}} >                        
    //       <Row className='mb-3'>
    //           <table className=" table-borderless">
    //             <thead>
    //               <tr>
    //                 <th  >
    //                     <div className='text-left col-2'  >
    //                          <Link to='/account'>
    //                           {
    //                             url != null ?
    //                             <img               
    //                            style={{width: '190px', height:130}}
    //                            src={url}
    //                            alt='..'  /> :
    //                            <Spinner animation="border" role="status">
    //                              <span className="visually-hidden">Loading...</span>
    //                            </Spinner>
    //                           }
    //                          </Link>
    //                     </div>
                           
    //                   </th>
    //                 <th  >
    //                   <div className="text-center col-10" >
    //                      <h2 className="text-center">CERTIFICATE OF POSSESSORY LIEN FORECLOSURE</h2>
    //                      <h4 className="text-center">(ORS 98.830 - TOWING ABANDONED VEHICLE FROM PRIVATE PROPERTY)</h4>
    //                      <h6 className="custom-justify">(You may obtain copies of laws at your local library or at www.oregonlegislature.gov/bills_laws/Pages/ORS.aspx)</h6>
    //                   </div>                         
    //                 </th>
    //               </tr>
    //             </thead>  
    //           </table>
    //       </Row>

    //       <Row className='mb-3'>
    //           <table className=" table-borderless">
    //             <thead>
    //               <tr>                    
    //                 <th  >
    //                   <div className='custom-justify mx-auto p-4' >
    //                     <h4 className="custom-justify">
    //                         This certification is for use in support of Oregon title applications where transfer results from the foreclosure of a lien created 
    //                         under ORS 98.830 as the result of an abandoned vehicle being removed from private property at the request of the owner or lawful possessor of the property.
    //                     </h4>
    //                     <h4 className="custom-justify">
    //                           ORS 822.093 requires lien claimants to keep records sufficient to establish that all vehicles being sold or offered for sale 
    //                           were acquired by the lien claimant as the result of a possessory lien. Records, incluiding a signed and dated copy of this
    //                           DMV certificate of possessory lien foreclosure form, must be retainedfor a period of five years by the lien claimant. Failure to maintain lien records may result 
    //                           in a civil penalty of up to $1,000 per violation. 
    //                     </h4>
    //                   </div>                         
    //                 </th>
    //               </tr>
    //             </thead>
    //             <tbody>
    //               <tr><td></td></tr>
    //             </tbody>               
    //           </table>
    //       </Row>
    //       <Row className='mb-3'>
    //         <table className=" table-borderless" >
    //               <thead>
    //                 <tr>
    //                   <th className='p-5'>
    //                     <h4>I certify that:</h4>
    //                     <ul className='text-justify fs-4'>
    //                       <li>I, or the firm I represent, at the request of the owner or lawful possessor of private property, 
    //                           removed the following described vehicle from said property, as provided in ORS 98.830.
    //                       </li>
    //                       <li>I foreclosed the possessory lien in accordance with ORS 98.812(2), ORS 98.854, ORS 87.172(3) and ORS 87.176 to 87.206; and</li>
    //                       <li>I complied with all applicable statutory requirements, incluiding notification of all security 
    //                           interest holders as listed on the certificate of title, in accordance with ORS 87.196.
    //                       </li>
    //                       <li>The vehicle described below was sold at public auction to:</li>
    //                     </ul>

    //                     <h2 className='text-center'>Buyer's name and date of auction required.</h2>
    //                     <div>
    //                     <div className="col-2"></div>
    //                     <div className='col-8 text-center'>
    //                     <div className="input-group mb-3 mt-5 d-flex justify-content-center">
    //                       <input type="text" className="form-control underline-input" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2" />
    //                       <div className="input-group-append">
    //                         <span className="transparent-span align-center" id="basic-addon2">(Buyer's printed name)</span>
    //                       </div>                          
    //                     </div>
    //                     <div className=" input-group mb-3 d-flex justify-content-center">
    //                       <input type="text" className="form-control underline-input" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2" />
    //                       <div className="input-group-append">
    //                         <span className="transparent-span align-center" id="basic-addon2">(Location of auction)</span>
    //                       </div>                           
    //                     </div>
    //                     <div className=" input-group mb-3 d-flex justify-content-center">
    //                       <input type="text" className="form-control underline-input" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2" />
    //                       <div className="input-group-append align-center">
    //                         <span className="transparent-span" id="basic-addon2">(Date of auction)</span>
    //                       </div>     
    //                     </div>
    //                     </div>
    //                     <div className="col-2"></div>
    //                     </div>
    //                   </th>
    //                 </tr>
    //               </thead>
    //               <tbody>
    //                 <tr>
    //                 <td></td>
    //                 </tr>
    //               </tbody>
    //             </table>
    //             <table className="table  table-sm">
    //                <tbody>
    //                    <tr>
    //                       <td><TextField fullWidth id="Lic" name='Lic' type='text' label="License Plate Number" variant="standard" onChange={myChangeHandlersetlicense} /></td>
    //                       <td><TextField fullWidth id="State" name='State' type='text' label="State" variant="standard" onChange={myChangeHandlersetstate} /></td>
    //                       <td><TextField fullWidth id="Year" name='Year' type='text' label="Year" variant="standard" onChange={myChangeHandlersetyear} /></td>
    //                       <td><TextField fullWidth id="Make" name='Make' type='text' label="Make" variant="standard" onChange={myChangeHandlersetmake} /></td> 
    //                       <td><TextField fullWidth id="Model" name='Model' type='text' label="Model" variant="standard" onChange={myChangeHandlersetmodel} /></td>  
    //                    </tr>  
    //                    <tr>
    //                       <td colSpan="5"><TextField fullWidth id="Vin" name='Vin' type='text' label="VIN #" variant="standard" onChange={myChangeHandlersetvin} /></td>

    //                   </tr>  
    //                   <tr>
    //                       <td colSpan="5">
    //                          <h4 className="text-justify pl-5 pr-5">
    //                              I (the lien claimant) further certify that all information given is true and accurate and thet I am 
    //                              making this certificationin support of an application for title. I understand it is a crime under ORS 803.075
    //                              to certify the truth of a statement when I know the stattement is not true. Such a crime is a class A misdemeanor
    //                              punishable by a jall sentence of up to 1 year and a fine of up to $6,250, or both.     
    //                          </h4>
    //                       </td>
    //                  </tr>
    //                  <tr>
    //                     <td colSpan='4' ><TextField fullWidth id="Name" name='Name' type='text' label="Full Name" variant="standard" onChange={myChangeHandlersetname} /></td>
    //                     <td colSpan='1' ><TextField fullWidth  id="Phone" name='Phone' type='text'  label="Phone Number" variant="standard" onChange={myChangeHandlersetphone}  /></td>
                      
    //                  </tr>
    //                  <tr>
    //                    <td colSpan='2' ><TextField fullWidth id="Lic" name='Lic' type='text' label="License Plate Number" variant="standard" onChange={myChangeHandlersetlicense} /></td>
    //                    <td colSpan='1' ><TextField fullWidth id="State" name='State' type='text' label="State" variant="standard" onChange={myChangeHandlersetstate} /></td>
    //                    <td colSpan='1' ><TextField fullWidth  id="Keyss" name='Keyss' type='text'  label="Keys" variant="standard"  onChange={myChangeHandlersetkeyss} /></td>
    //                    <td>
    //                     <tr>
    //                     <td  style={{width: "15%"}}><TextField fullWidth id="Vin" name='Vin' type='text' label="VIN #" variant="standard" onChange={myChangeHandlersetvin} /></td>   
    //                     <td  style={{width: "15%" }}><TextField fullWidth id="Towedf" name='Towedf' type='text' label="Towed From" variant="standard" onChange={myChangeHandlersettowedf} /></td>
    //                     </tr>
    //                     </td>
    //                  </tr>
    //                  <tr>
    //                    <td colSpan="3"><TextField fullWidth  id="Keyss" name='Keyss' type='text'  label="Keys" variant="standard"  onChange={myChangeHandlersetkeyss} /></td>
    //                    <td colSpan="1"><TextField fullWidth  id="Date1" name='Date1' type='datetime-local' label='Date'  variant="standard" onChange={myChangeHandlersetdate1}  /></td>
    //                    <td colSpan="1"><TextField fullWidth  id="Driver" name='Driver' type='text'  label="Driver" variant="standard"  onChange={myChangeHandlersetdriver} /></td>
    //                  </tr>                     
    //                  </tbody>
    //                  </table>

    //         </Row>
    //         <Row>
    //             <table>
    //                 <tbody>
    //                 <tr className='text-center'>
    //                     <div style={{border:'2'}} className='border text-center p-6 '>
    //                     <div className='border text-center p-6 '>
    //                         <h2 className='text-bold'>COMPLETE IN FULL AND SIGN.</h2>
    //                         <h5>This form must be submitted to DMV with the application for title.</h5>
    //                     </div>
    //                     </div>
    //                  </tr>
    //                  <tr>                     
    //                  </tr>
    //                 </tbody>
    //             </table>
    //             <div className="text-white mt-1">
    //                   <div className="d-flex justify-content-between">
    //                     <span className="bg-black pl-1">735-519 (1-18)</span>
    //                     <span className="bg-black pr-1">735-519 (1-18)</span>
    //                   </div>
    //             </div>
    //         </Row>
          
       
                              
    //   </Container>


)}

export default FormTowing