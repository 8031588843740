import React, { useState, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../auth/users/userSlice'
import {Container, Row, Col, Button, Modal } from "react-bootstrap"
import * as invoicesActions from '../store/actions/invoice'
import * as estimatesActions from '../store/actions/estimate'
import * as profilesActions from '../store/actions/profile'
import * as expensesActions from '../store/actions/balance'
import { Link } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import './NavigateAccount.css'




    const NavigateAccount = () => {

    const user = useSelector(selectUser)

    const [show, setShow] = useState(false);
    const [showSearchInvoice, setShowSearchInvoice] = useState(false);
    

    const dispatch = useDispatch();
   

   

    const searchByPhone = useSelector(state => state.invoices.invoices)
    
    const [inputfiltro, setInputFiltro] = useState('')
    const [filterPhone, setFilterPhone] = useState(null)


    const  onChangeHandlerPhone = (event) => {
      setInputFiltro(event.target.value);   
     }



     const handlerSubmitclient = () => {
      if (inputfiltro) {
        const filtered = searchByPhone.filter(item => item.Phone === inputfiltro);
        setFilterPhone(filtered);
    }    
     }

     const handlerSubmitSearchInvoice = () => {
      if (inputfiltro) {
        const filtered = searchByPhone.filter(item => item.Phone === inputfiltro);
        setFilterPhone(filtered);
    }    
     }

     function oncClosemodal() {
      setFilterPhone(null)
       setShow(false)
     }

    


    const loadInvoices = useCallback( async () => {
            const useee = user.uid
            dispatch(invoicesActions.fetchInvoices(useee))
        },[dispatch]);

    const loadEstimates = useCallback( async () => {
        dispatch(estimatesActions.fetchEstimates())
    },[dispatch]);

   const loadProfiles = useCallback( async () => {
         dispatch(profilesActions.fetchProfiles())  
      },[dispatch]);

    const handlerSubmit = () => {
      const useee = user.uid
      dispatch(invoicesActions.fetchInvoices(useee))        
    }

    const handlerSubmitestimate = () => {
      const useee = user.uid
      dispatch(estimatesActions.fetchEstimates(useee))       
    }
    
    const profilesHandler = () => {
      const useee = user.uid
      dispatch(profilesActions.fetchProfiles(useee))     
    }

       const ourClientHandler =() => {
         const useee = user.uid
        dispatch(invoicesActions.fetchInvoices(useee))
        setShow(true)
         }

         const handlerSearchClick =() => {
          const useee = user.uid
         dispatch(invoicesActions.fetchInvoices(useee))
         setShowSearchInvoice(true)
          }

         const searchInvoicestHandler =() => {
          const useee = user.uid
         dispatch(invoicesActions.fetchInvoices(useee))
         setShow(true)
          }

         const searchInvoicetHandler =() => {
          const useee = user.uid
         dispatch(invoicesActions.fetchInvoices(useee))
         setShow(true)
          }

    const expensesHandler = () => {
      const useee = user.uid
      dispatch(expensesActions.fetchExpenses(useee))
    }


    useEffect(() => {
      if (inputfiltro) {
          setFilterPhone(searchByPhone.filter(item => item.Phone === inputfiltro));
      }
  }, [searchByPhone, inputfiltro]);
         
    return (
        <Container>
            <Row>
                <Col md={3}>     
                              
                    <div className="d-grid gap-2 mb-3">
                       <Button style={{width: '100%'}} variant="outline-info" size="lg" onClick={ourClientHandler}>
                         Our Clients
                       </Button>
                       <Modal
                         show={show}
                         onHide={() => setShow(false)}
                         aria-labelledby="contained-modal-title-vcenter"
                         centered
                         backdrop="static"
                         keyboard={false}
                         >
                         <Modal.Header closeButton>
                           <Modal.Title id="example-custom-modal-styling-title1">
                             Search
                           </Modal.Title>
                         </Modal.Header>
                         <Modal.Body>
                           <Row>
                             <Col>
                                  <Autocomplete                      
                                      size='small'
                                      id="idPhone"
                                      options={ searchByPhone}
                                      getOptionLabel={(option) => option.Phone}
                                      style={{ width: 180 }}
                                      renderInput={(params) => <TextField {...params} id="Phone" name="Phone" type='text' label="--Phone Number-- "  variant="standard"  onSelect={onChangeHandlerPhone } />}
                                    /> 
                             </Col>

                             {inputfiltro && filterPhone && filterPhone.length > 0 && (
                                        <Col>
                                            <div className="mb-2">
                                                <Link
                                                    to={{
                                                        pathname: '/account/ourclientinvoice',
                                                        state: { id: filterPhone[0].id },
                                                    }}
                                                >
                                                    <Button
                                                        style={{ width: '50%' }}
                                                        variant="outline-info"
                                                        onClick={handlerSubmitclient}
                                                    >
                                                        Search
                                                    </Button>
                                                </Link>
                                            </div>
                                        </Col>
                                    )}
                           
                            </Row>
                         
                         </Modal.Body>
                         <Modal.Footer>
                          <Button onClick={oncClosemodal} variant="outline-info">Close</Button>
                        </Modal.Footer>
                       </Modal>
                    </div>
                    
                </Col>                
                <Col md={3}>
                      <div className="d-grid gap-2 mb-3">
                      <Link to='/account/invoice'  onClick={handlerSubmit}  >
                          <Button style={{width: '100%'}} variant="outline-info" size="lg">
                             Create Invoice
                          </Button>
                      </Link>
                      </div>
                      {/* <div className="d-grid gap-2  mb-3">
                      <Link to='/account/estimate' >
                          <Button style={{width: '100%'}} variant="outline-info" size="lg">
                             Create Estimate
                          </Button>
                      </Link>
                      </div>
                      <div className="d-grid gap-2  mb-3">
                      <Link to='/account/inspection' >
                          <Button style={{width: '100%'}} variant="outline-info" size="lg">
                            General Inspection
                          </Button>
                      </Link>
                      </div> */}
                </Col>
               
                <Col md={3}>
    <div className="d-grid gap-2 mb-3">
        {/* Botón para el modal de búsqueda de facturas */}
        <Button 
            style={{ width: '100%' }} 
            variant="outline-info" 
            size="lg" 
            // onClick={() => setShowSearchInvoice(true)}
            onClick={handlerSearchClick}
        >
            Search Invoices
        </Button>
        {/* Modal de búsqueda de facturas */}
        <Modal
            show={showSearchInvoice}
            onHide={() => setShowSearchInvoice(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title3">Search</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Autocomplete
                            size="small"
                            id="idPhone"
                            options={searchByPhone}
                            getOptionLabel={(option) => option.Phone}
                            style={{ width: 180 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    id="Phone"
                                    name="Phone"
                                    type="text"
                                    label="--Phone Number--"
                                    variant="standard"
                                    onSelect={onChangeHandlerPhone}
                                />
                            )}
                        />
                    </Col>
                    {inputfiltro && filterPhone && filterPhone.length > 0 && (
                        <Col>
                            <div className="mb-2">
                                <Link
                                    to={{
                                        pathname: '/account/search',
                                        state: { id: filterPhone[0].id },
                                    }}
                                >
                                    <Button
                                        style={{ width: '50%' }}
                                        variant="outline-info"
                                        onClick={handlerSubmitSearchInvoice}
                                    >
                                        Search
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                    )}
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShowSearchInvoice(false)} variant="outline-info">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
</Col>
            </Row>   
            <Row>
              <Col md={3}>
              </Col>
              <Col md={3}>
              <div className="d-grid gap-2 mb-3">
                      <Link to='/account/formtowing'  onClick={handlerSubmit}  >
                          <Button style={{width: '100%'}} variant="outline-info" size="lg">
                             Form 98.830
                          </Button>
                      </Link>
                      </div>
              </Col>
              <Col md={3}>
              </Col>
              <Col md={3}>
              </Col>
            </Row>          
            </Container>         
    )
}

export default NavigateAccount

